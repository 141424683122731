import qs from 'qs';
import exportFile from 'quasar/src/utils/export-file.js';import date from 'quasar/src/utils/date.js';import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import { mapActions, mapGetters } from 'vuex';

export default {
  methods: {
    ...mapActions('Account', ['ActionSetUser', 'ActionSetToken']),
    ...mapActions(['ActionSetPagination']),
    objectToQuery(params) {
      return Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('%26');
    },
    validURL(str) {
      let pattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i'
      ); // fragment locator
      return !!pattern.test(str);
    },
    getNameImage(file) {
      const fullname = file.__key.substring(13);
      const [name] = fullname.split('.');
      return name;
    },
    parseMoney(value) {
      if (value) {
        return Number.parseFloat(value).toLocaleString('pt-BR', {
          style: 'currency',
          currency: 'BRL'
        });
      }
      return '-';
    },
    /**
     * parseQs
     * @param {*} value
     * @returns
     */
    parseQs(value) {
      return qs.stringify(value);
    },
    short(val, qtd) {
      return (val && val.length > qtd) || 'Muito curto';
    },
    isRequiredNew(val) {
      return (
        !!val ||
        (Array.isArray(val) && val.length === 0) ||
        (typeof value == 'string' && !val.trim()) ||
        'Este item é obrigatorio!'
      );
    },
    isRequired(val) {
      return !!val || this.$t('is_required');
    },
    isPassword(val) {
      let msg = '';
      if (!!val && !val.match(/[^a-zA-Z0-9]/)) msg = 'Senha deve conter no minimo 1 caracter especial';
      if (!!val && !val.match(/[0-9]/)) msg = 'Senha deve conter no minimo 1 numero';
      if (!!val && !val.match(/[A-Z]/)) msg = 'Senha deve conter no minimo 1 letra maiuscula';
      if (!!val && !val.match(/[a-z]/)) msg = 'Senha deve conter no minimo 1 letra minuscula';
      if (!!val && val.length > 20) msg = 'Senha deve conter no maximo 20 caracteres';
      if (!!val && val.length < 8) msg = 'Senha deve conter no minimo 8 caracteres';
      return !msg || msg;
    },
    isEmail(val) {
      const emailPattern =
        /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || 'Email invalido!';
    },

    isCNPJ(val) {
      return val ? this.validarCNPJ(val) : false;
    },

    validarCNPJ(cnpj) {
      let erroCNPJ = 'CNPJ inválido';

      if (!cnpj) return false;
      cnpj = cnpj.replace(/[^\d]+/g, '');
      if (cnpj == '') return false;
      if (cnpj == null) return false;
      if (cnpj.length != 14) return erroCNPJ;
      if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
      )
        return erroCNPJ;
      let size = cnpj.length - 2;
      let numbers = cnpj.substring(0, size);
      let digits = cnpj.substring(size);
      let sum = 0;
      let pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
      }
      let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != digits.charAt(0)) return erroCNPJ;
      size = size + 1;
      numbers = cnpj.substring(0, size);
      sum = 0;
      pos = size - 7;
      for (let i = size; i >= 1; i--) {
        sum += numbers.charAt(size - i) * pos--;
        if (pos < 2) pos = 9;
      }
      result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
      if (result != digits.charAt(1)) return erroCNPJ;
      return true;
    },

    isCPF(val) {
      return val ? this.validarCPF(val) : false;
    },

    validarCPF(cpf) {
      let erroCPF = 'CPF inválido';
      let add = 0;
      let i = 0;
      let rev;

      cpf = cpf.replace(/[^\d]+/g, '');
      if (cpf == '') return false; // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
      )
        return erroCPF; // Valida 1o digito
      add = 0;
      for (i = 0; i < 9; i++) {
        add += parseInt(cpf.charAt(i)) * (10 - i);
      }
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(9))) {
        return erroCPF;
      } // Valida 2o digito
      add = 0;
      for (i = 0; i < 10; i++) {
        add += parseInt(cpf.charAt(i)) * (11 - i);
      }
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) {
        rev = 0;
      }
      if (rev != parseInt(cpf.charAt(10))) {
        return erroCPF;
      }
      return true;
    },

    /**
     * maskCnpj
     * @param {*} cnpj
     * @returns
     */
    maskCnpj(cnpj) {
      let mask;
      if (cnpj.length <= 14)
        return cnpj.replace(/\D/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
      return mask;
    },
    copyData(value) {
      copyToClipboard(value)
        .then(() => {
          this.successNotify('Copiado!');
        })
        .catch(() => {
          this.errorNotify('Erro ao copiar');
        });
    },

    /**
     * showDialog
     * @param {*} title
     * @param {*} message
     * @param {*} onOk
     * @param {*} onCancel
     * @param {*} onDismiss
     */
    showDialog(title, message, onOk, onCancel, onDismiss) {
      this.$q
        .dialog({
          title: title ? title : this.$t('atention'),
          message: message ? message : '',
          html: true,
          persistent: true,
          class: 'q-pa-md',
          ok: {
            value: true,
            padding: 'sm lg',
            label: this.$t('yes'),
            unelevated: true,
            color: 'primary',
            ['no-caps']: true
          },
          cancel: {
            value: true,
            padding: 'sm lg',
            label: this.$t('cancel'),
            unelevated: true,
            color: 'negative',
            ['no-caps']: true
          }
        })
        .onOk(() => {
          onOk ? onOk() : '';
        })
        .onCancel(() => {
          onCancel ? onCancel() : '';
        })
        .onDismiss(() => {
          onDismiss ? onDismiss() : '';
        });
    },

    showInfoDialog(title, message, onOk) {
      this.$q
        .dialog({
          title: title ? title : this.$t('atention'),
          message: message ? message : '',
          html: true,
          persistent: true,
          class: 'q-pa-md',
          ok: {
            value: true,
            padding: 'sm lg',
            label: this.$t('ok'),
            unelevated: true,
            color: 'primary',
            ['no-caps']: true
          }
        })
        .onOk(() => {
          onOk ? onOk() : '';
        });
    },

    errorNotify(errorMessage) {
      let error = errorMessage.response ? errorMessage.response.data : errorMessage;
      if (error.message) error = error.message;
      if (error.Error) error = error.Error;
      if (error.error) error = error.error;
      if (error.date) error = 'A data informada é inválida';

      this.$q.notify({
        ///type: 'negative',
        position: 'top',
        timeout: 60000,
        message: this.$t(error),
        html: false,
        color: 'red-4',
        actions: [{ icon: 'close', color: 'white' }]
      });
    },

    successNotify(showMessage) {
      this.$q.notify({
        type: 'positive',
        position: 'top',
        timeout: 60000,
        message: showMessage,
        actions: [{ icon: 'close', color: 'white' }]
      });
    },

    infoNotify(showMessage) {
      this.$q.notify({
        type: 'primary',
        message: showMessage
      });
    },

    onLoading(value) {
      if (value)
        this.$q.loading.show({
          spinnerColor: 'white',
          spinnerSize: 100,
          backgroundColor: 'accent',
          message: this.$t('loading'),
          messageColor: 'white'
        });
      else this.$q.loading.hide();
    },

    clickToCopy(text) {
      copyToClipboard(text)
        .then(() => {
          this.successNotify('Conteúdo copiado com sucesso.');
        })
        .catch(() => {
          this.errorNotify('Ocorreu um erro, tente novamente.');
        });
    },

    wrapCsvValue(val, formatFn) {
      let formatted = formatFn !== void 0 ? formatFn(val) : val;
      formatted = formatted === void 0 || formatted === null ? '' : String(formatted);
      formatted = formatted.split('"').join('""');
      return `"${formatted}"`;
    },

    exportTableCsv(data, columns, name = 'table-export') {
      const content = [columns.map((col) => this.wrapCsvValue(col.label)).join(';')]
        .concat(
          data.map((row) =>
            columns
              .map((col) =>
                this.wrapCsvValue(
                  typeof col.field === 'function' ? col.field(row) : row[col.field === void 0 ? col.name : col.field],
                  col.format
                )
              )
              .join(';')
          )
        )
        .join('\r\n');

      const status = exportFile(`${name}_${this.$moment(new Date()).format()}.csv`, content, 'text/csv');

      if (status !== true) {
        this.$q.notify({
          message: 'Browser denied file download...',
          color: 'negative',
          icon: 'warning'
        });
      }
    },

    parceToUrl(file) {
      if (file) return URL.createObjectURL(file);
      return;
    },

    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    stringToDate(date, format, delimiter) {
      let formatItems = format.toLowerCase().split(delimiter);
      let dateItems = date.split(delimiter);
      let monthIndex = formatItems.indexOf('mm');
      let dayIndex = formatItems.indexOf('dd');
      let yearIndex = formatItems.indexOf('yyyy');
      let month = parseInt(dateItems[monthIndex]);
      month -= 1;
      let formatedDate = new Date(dateItems[yearIndex], month, dateItems[dayIndex]);
      return formatedDate;
    },

    formatToPt(value) {
      if (value) {
        let nowDate = new date.extractDate(value, 'YYYY-MM-DD');
        if (date.isValid(nowDate)) return date.formatDate(nowDate, 'DD/MM/YYYY');
      }
      return '';
    },
    formatToEn(value) {
      if (value) {
        let nowDate = new date.extractDate(value, 'DD/MM/YYYY');
        if (date.isValid(nowDate)) return date.formatDate(nowDate, 'YYYY-MM-DD');
      }
      return '';
    },
    async hasAdBlock() {
      try {
        await this.$http.head('https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js');
        return false;
      } catch (e) {
        return true;
      }
    },
    setStoreExpiration(key, value, ttl) {
      const now = new Date();
      const item = {
        value,
        expire: now.getTime() + ttl * 1000
      };
      localStorage.setItem(key, JSON.stringify(item));
    },
    getStoreExpiration(key) {
      const store = localStorage.getItem(key);
      if (!store) return false;
      const item = JSON.parse(store);
      const now = new Date();
      if (now.getTime() > item.expire) {
        localStorage.removeItem(key);
        return false;
      }
      return item.value;
    },
    async dialogAdBlock() {
      if (!this.getStoreExpiration('afilio_adBlock') && (await this.hasAdBlock())) {
        this.$q
          .dialog({
            message: `<h4>Atenção - Ad Blocker Detected!</h4> A plataforma Afilio detectou que você tem instalado no seu navegador um AD BLOCKER,
                   e isso pode afetar a sua experiência.<br> Por favor, considere desativar a extensão do seu navegador.`,
            type: 'negative',
            persistent: false,
            html: true,
            style: 'width:800px',
            class: 'q-pa-md bg-negative text-white',
            ok: {
              value: true,
              padding: 'sm lg',
              label: 'Ok',
              unelevated: true,
              color: 'white',
              'text-color': 'negative',
              ['no-caps']: true
            },
            cancel: {
              value: true,
              padding: 'sm lg',
              label: this.$t('cancel'),
              unelevated: true,
              color: 'white',
              outline: true,
              ['no-caps']: true
            }
          })
          .onOk(() => {
            this.setStoreExpiration('afilio_adBlock', true, 60 * 60 * 24);
          })
          .onCancel(() => {});
      }
    },
    scorePassword(pass) {
      let score = 0;
      if (!pass) return score;
      // award every unique letter until 5 repetitions
      let letters = new Object();
      for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
      }
      // bonus points for mixing it up
      let variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
      };

      let variationCount = 0;
      for (var check in variations) {
        variationCount += variations[check] == true ? 1 : 0;
      }
      score += (variationCount - 1) * 10;
      return parseInt(score);
    },

    defaultFilter(val, filterVar, listVar, prop = null) {
      const needle = val.toLocaleLowerCase();
      this[filterVar] = needle
        ? listVar.filter((v) =>
            prop ? v[prop].toLocaleLowerCase().includes(needle) : v.toLocaleLowerCase().includes(needle)
          )
        : listVar;
    },

    uuidV4() {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
      );
    },

    isValidTime(val) {
      if (val) return val.match(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/) || 'Valor de tempo inválido.';
    },

    isValidDate(val) {
      if (val)
        return (
          val.match(
            /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
          ) || 'Data inserida inválida.'
        );
    }
  },
  computed: {
    ...mapGetters('Account', ['user', 'token']),
    ...mapGetters(['reqPagination'])
  }
};
