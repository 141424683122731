import axios from 'axios';
import store from '@/store';
// import mixinGlobals from "@/axios";
// import vue from "@/mixins";
export default async (to, from, next) => {
  if (to.name === 'AutoLogin' && to.params.token) {
    localStorage.setItem('afilio_store', to.params.token);
    localStorage.setItem('afilio_language', 'pt-br');
  }
  const token = localStorage['afilio_store'] ? localStorage.getItem('afilio_store') : null;
  const http = axios.create({ baseURL: process.env.VUE_APP_PORTEIRA_API });
  http.interceptors.request.use(
    (config) => {
      if (token) config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => Promise.reject(error)
  );

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      if (!token) throw 'token não informado';
      const { data } = await http.get(`v2/auth`);
      if (!data) throw 'session nao iniciada';
      store.dispatch('Account/ActionSetUser', data.auth.user);
      if (data.auth.user.profile == 'afiliado')
        if (to.meta.role.filter((item) => item == 'affiliate').length > 0) next();
        else next({ name: 'Dashboard' });
      else if (data.auth.user.profile == 'anunciante') {
        if (to.meta.role.filter((item) => item == 'advertiser').length > 0) next();
        else next({ name: 'Dashboard' });
      } else next();
    } catch (error) {
      error.response ? console.log(error.response.data) : console.log(error);
      store.dispatch('User/ActionSetUsers', null);
      next({ name: 'Auth' });
    }
  } else {
    try {
      if (!token) throw 'token não informado';
      await http.get(`v2/auth`);
      next({ name: 'Dashboard' });
    } catch (error) {
      error.response ? console.log(error.response.data) : console.log(error);
      store.dispatch('User/ActionSetUsers', null);
      if (to.name === 'AutoLogin') next({ name: 'Auth' });
      next();
    }
  }
};
