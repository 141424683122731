export default {
  namespaced: true,
  state: {
    dialogFinancial: false,
    dialogFinancialPI: false,
    dialogEditPI: false,
    pis: [],
    filterPis: [],
    infoPIs: [],
    infoEditPIs: [],
    billings: [],
    filterBillings: [],

    affiliateBillings: [],

    schedulePayments: [],
    invoicesPayments: [],
    billingsByAffiliate: [],
    customPayments: [],
    filterSchedulePayments: null,
    filterInvoicesPayments: null,
    filterInvoicesPaymentsStep: null,
    filtercustomPayments: null,
    invoicesPaymentsStep: []
  },
  getters: {
    dialogFinancial: (state) => state.dialogFinancial,
    dialogFinancialPI: (state) => state.dialogFinancialPI,
    dialogEditPI: (state) => state.dialogEditPI,
    pis: (state) => state.pis,
    filterPis: (state) => state.filterPis,
    infoPIs: (state) => state.infoPIs,
    infoEditPIs: (state) => state.infoEditPIs,
    billings: (state) => state.billings,
    filterBillings: (state) => state.filterBillings,

    affiliateBillings: (state) => state.affiliateBillings,

    schedulePayments: (state) => state.schedulePayments,
    filterSchedulePayments: (state) => state.filterSchedulePayments,
    invoicesPayments: (state) => state.invoicesPayments,
    billingsByAffiliate: (state) => state.billingsByAffiliate,
    filterBillingsByAffiliate: (state) => state.filterBillingsByAffiliate,
    filterInvoicesPayments: (state) => state.filterInvoicesPayments,
    customPayments: (state) => state.customPayments,
    filtercustomPayments: (state) => state.filtercustomPayments,
    filterInvoicesPaymentsStep: (state) => state.filterInvoicesPaymentsStep,
    invoicesPaymentsStep: (state) => state.invoicesPaymentsStep
  },
  mutations: {
    SET_DIALOG_FINANCIAL(state, payload) {
      state.dialogFinancial = payload;
    },
    SET_DIALOG_FINANCIAL_PI(state, payload) {
      if (payload.value) {
        state.infoPIs = payload.value;
        state.dialogFinancialPI = true;
      } else {
        state.dialogFinancialPI = payload;
        state.infoPIs = null;
      }
    },
    SET_DIALOG_EDIT_PI(state, payload) {
      if (payload.value) {
        state.infoEditPIs = payload.value;
        state.dialogEditPI = true;
      } else {
        // state.infoEditPIs = null
        state.dialogEditPI = payload;
      }
    },
    SET_PIS(state, payload) {
      state.pis = payload;
    },
    SET_FILTER_PIS(state, payload) {
      state.filterPis = payload;
    },
    SET_BILLINGS(state, payload) {
      state.billings = payload;
    },
    SET_FILTER_BILLINGS(state, payload) {
      state.filterBillings = payload;
    },

    SET_AFFILIATE_BILLINGS(state, payload) {
      state.affiliateBillings = payload;
    },

    SET_SCHEDULE_PAYMENTS(state, payload) {
      state.schedulePayments = payload;
    },
    SET_FILTER_SCHEDULE_PAYMENTS(state, payload) {
      state.filterSchedulePayments = payload;
    },
    SET_INVOICES_PAYMENTS(state, payload) {
      state.invoicesPayments = payload;
    },
    SET_BILLINGS_BY_AFFILIATE(state, payload) {
      state.billingsByAffiliate = payload;
    },
    SET_FILTER_BILLINGS_BY_AFFILIATE(state, payload) {
      state.filterBillingsByAffiliate = payload;
    },
    SET_FILTER_INVOICES_PAYMENTS(state, payload) {
      state.filterInvoicesPayments = payload;
    },
    SET_FILTER_INVOICES_PAYMENTS_STEP(state, payload) {
      state.filterInvoicesPaymentsStep = payload;
    },
    SET_INVOICES_PAYMENTS_STEP(state, payload) {
      state.invoicesPaymentsStep = payload;
    },
    SET_CUSTOM_PAYMENTS(state, payload) {
      state.customPayments = payload;
    },
    SET_FILTER_CUSTOM_PAYMENTS(state, payload) {
      state.filtercustomPayments = payload;
    }
  },
  actions: {
    ActionSetDialogFinancial({ commit }, payload) {
      commit('SET_DIALOG_FINANCIAL', payload);
    },
    ActionSetDialogFinancialPI({ commit }, payload) {
      commit('SET_DIALOG_FINANCIAL_PI', payload);
    },
    ActionSetDialogEditPi({ commit }, payload) {
      commit('SET_DIALOG_EDIT_PI', payload);
    },
    ActionSetPis({ commit }, payload) {
      commit('SET_PIS', payload);
    },
    ActionSetFilterPis({ commit }, payload) {
      commit('SET_FILTER_PIS', payload);
    },
    ActionSetBillings({ commit }, payload) {
      commit('SET_BILLINGS', payload);
    },
    ActionSetFilterBillings({ commit }, payload) {
      commit('SET_FILTER_BILLINGS', payload);
    },

    ActionSetAffiliateBillings({ commit }, payload) {
      commit('SET_AFFILIATE_BILLINGS', payload);
    },

    ActionSetSchedulePayments({ commit }, payload) {
      commit('SET_SCHEDULE_PAYMENTS', payload);
    },
    ActionSetFilterSchedulePayments({ commit }, payload) {
      commit('SET_FILTER_SCHEDULE_PAYMENTS', payload);
    },
    ActionSetInvoicesPayments({ commit }, payload) {
      commit('SET_INVOICES_PAYMENTS', payload);
    },
    ActionSetBillingsByAffiliate({ commit }, payload) {
      commit('SET_BILLINGS_BY_AFFILIATE', payload);
    },
    ActionSetFilterBillingsByAffiliate({ commit }, payload) {
      commit('SET_FILTER_BILLINGS_BY_AFFILIATE', payload);
    },
    ActionSetFilterInvoicesPayments({ commit }, payload) {
      commit('SET_FILTER_INVOICES_PAYMENTS', payload);
    },
    ActionSetFilterInvoicesPaymentsStep({ commit }, payload) {
      commit('SET_FILTER_INVOICES_PAYMENTS_STEP', payload);
    },
    ActionSetInvoicesPaymentsStep({ commit }, payload) {
      commit('SET_INVOICES_PAYMENTS_STEP', payload);
    },
    ActionSetCustomPayments({ commit }, payload) {
      commit('SET_CUSTOM_PAYMENTS', payload);
    },
    ActionSetFilterCustomPayments({ commit }, payload) {
      commit('SET_FILTER_CUSTOM_PAYMENTS', payload);
    }
  }
};
